/**
 * Style specific to the Lab Member Feature
 *
 */

 .member {
    font-family: "open-sans", sans-serif;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #efefef;
    @include clearfix();
    .teaser__image {
        width: 28%;
        float: left;
    }
    .teaser__detail {
        float: left;
    }
  }
  
  .member__name {
    color: #4a5258;
    font-weight: 700;
  }
  
  .member__title {
    ul li {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
  
  .pane-node-field-member-professional-title {
    padding-bottom: 20px;
  }
  
  .member__focus {
    p {
      margin-top: 0;
    }
  }
  
  .member__vivo,
  .member__bio,
  .member__pops,
  .field-name-field-member-vivo-link,
  .field-name-field-member-pops-link {
    a {
      display: block;
      float: left;
      width: 100px;
      background-color: #3787b0;
      background-image: none !important;
      color: #fff;
      text-align: center;
      line-height: 20px;
      padding: 0.5em 0.75em;
      margin: 10px 10px 10px 0;
      font-size: 12px;
      font-size: 1.2rem;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      border-radius: 8px;
    }
  }
  
  .pane-lab-members-panel-pane-2, .pane-lab-members-panel-pane-1{
    h2{
      font-family: "open-sans" , sans-serif;
      text-transform: capitalize;
    }
  }
  
  .member__education div.item-list ul li.first{
      margin: 0 0 0.25em 1.5em;
      padding: 0;
      list-style-type: disc;
  }
  
  
  /*TRAINEES/STAFF*/
  
  .headshot-grid{
  }
  
  h3.pane-title {
      color: #B31B1B !important;
      font-weight: 600;
      text-transform: inherit;
      font-size: 30px;
      letter-spacing: 0px;
      clear: both;
      padding: 35px 0px 25px 0px;
  }
  
  .headshot {
      width: 144px;
      height: auto;
      margin-bottom: 10px;
  }
  
  .headshot-entry{
      float: left;
      width: 170px;
      margin: 0px 10px 0px 20px;
      height:325px;
  }
  
  .headshot-details {
      width: 144px;
  }
  
  .headshot-details .name {
      font-weight: bold;
  }
  
  .headshot-details p {
      font-size: 12px;
      font-size: 1.2rem;
      margin: 0;
      padding: 0 0 5px;
      line-height: 17px;
  }
  
  .panel-separator {
      margin: 0px;
  }
  
  /*WCM CTA Clear Left*/
  .wcm-cta {
      width: 220px;
      margin: 0 auto 20px;
      clear: left;
  }
  
  /*Carousel Thumb and Text Disable*/
  .thumbnail-controller{
      display: none;
  }
  
  .slide-text{
  }
  
  /*Member Profile Link Buttons*/
  .member__vivo,
  .member__bio,
  .member__pops,
  .member__lab,
  .field-name-field-member-vivo-link,
  .field-name-field-member-pops-link {
    a {
      display: block;
      float: left;
      width: 100px;
      background-color: #3787b0;
      color: #fff;
      font-family: "open-sans", sans-serif;
      text-align: center;
      padding: 0.5em 0.75em;
      margin: 10px 10px 10px 0;
      font-size: 1.2rem;
      border-radius: 8px;
    }
  }
  
  .biblio-title{
      font-weight: bold;
      text-decoration: none;
      font-style: normal;
      line-height: normal;
      text-align: left;
      font-family: "Open Sans",sans-serif;
      color: #cf4520;
  }
  
  .slide-image img{
      min-width: none;
      height: auto;
  }
  
  .mobile-pager {
      margin-bottom: 20px;
      width: 107%;
  }